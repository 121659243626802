import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
import {
  SUPPORTED_EXCEL_EXTENSIONS,
  SUPPORTED_IMAGE_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS,
} from "@root/shared/constants/supported-extensions.constants";
import { FileType } from "@root/shared/enums/file-type.enum";

export const getFileType = (fileName: string): FileType => {
  const fileNameSplits = fileName.split(".");
  const extensionName = fileNameSplits[fileNameSplits.length - 1].toLowerCase() as FileExtension;
  if (SUPPORTED_VIDEO_EXTENSIONS.includes(extensionName)) return FileType.Video;
  if (SUPPORTED_IMAGE_EXTENSIONS.includes(extensionName)) return FileType.Image;
  if (SUPPORTED_EXCEL_EXTENSIONS.includes(extensionName)) return FileType.Excel;
  return FileType.Other;
};
